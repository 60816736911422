/* Global */
.small-card {
  margin-bottom: 24px;
  margin-left: 12px;
  margin-right: 12px;
  max-width: 300px;
  align-self: center;
}

.large-card {
  max-width: 900px;
  align-self: center;
}

.gone {
  display: none;
}

.amazon-color-light {
  color: #f90 !important;
}

.amazon-color-dark {
  color: #b92c00 !important;
}

.michigan-color-light {
  color: #ffcb05 !important;
}

.michigan-color-dark {
  color: #00274c !important;
}

.android-color-light {
  color: #a4c639 !important;
}

.android-color-dark {
  color: #526b00 !important;
}

.web-color-light {
  color: #61dbfb !important;
}

.web-color-dark {
  color: #006a86 !important;
}

.gimble-color-light {
  color: #83b2da !important;
}

.gimble-color-dark {
  color: #0072d4 !important;
}

.justlight-color-dark {
  color: #f05151 !important;
}

.justlight-color-light {
  color: #ffcb70 !important;
}

.tesla-color-light {
  color: #E82127 !important;
}

.tesla-color-dark {
  color: #E82127 !important;
}

.full-stack-color-light {
  color: #CC8899 !important;
}

.full-stack-color-dark {
  color: #8f00FF !important;
}

.card-header {
  padding: 0 !important;
}

/* Global */
@keyframes react-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

img[src=""] {
  display: none;
}


/* Firework animation source: https://alvaromontoro.com/blog/68002/creating-a-firework-effect-with-css */
@keyframes firework {
  0% {
    transform: translate(-50%, 60vh);
    width: 0.5vmin;
    opacity: 1;
  }

  30% {
    width: 0.5vmin;
    opacity: 1;
  }

  100% {
    width: 60vmin;
    opacity: 0;
  }
}

.firework,
.firework::before,
.firework::after {
  --top: 60vh;

  content: "";
  position: fixed;
  bottom: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 0.5vmin;
  aspect-ratio: 1;
  background:
    /* random backgrounds */
    radial-gradient(circle, #ff0 0.2vmin, #0000 0) 50% 00%,
    radial-gradient(circle, #00f 0.3vmin, #0000 0) 00% 50%,
    radial-gradient(circle, #00f 0.5vmin, #0000 0) 50% 99%,
    radial-gradient(circle, #ff0 0.2vmin, #0000 0) 99% 50%,
    radial-gradient(circle, #ff0 0.3vmin, #0000 0) 80% 90%,
    radial-gradient(circle, #00f 0.5vmin, #0000 0) 95% 90%,
    radial-gradient(circle, #ff0 0.5vmin, #0000 0) 10% 60%,
    radial-gradient(circle, #00f 0.2vmin, #0000 0) 31% 80%,
    radial-gradient(circle, #00f 0.3vmin, #0000 0) 80% 10%,
    radial-gradient(circle, #ff0 0.2vmin, #0000 0) 90% 23%,
    radial-gradient(circle, #ff0 0.3vmin, #0000 0) 45% 20%,
    radial-gradient(circle, #00f 0.5vmin, #0000 0) 13% 24%,
    radial-gradient(circle, #ff0 0.4vmin, #0000 0) 25% 75%,
    radial-gradient(circle, #00f 0.6vmin, #0000 0) 70% 30%,
    radial-gradient(circle, #00f 0.7vmin, #0000 0) 40% 60%,
    radial-gradient(circle, #ff0 0.5vmin, #0000 0) 60% 40%,
    radial-gradient(circle, #ff0 0.3vmin, #0000 0) 10% 90%,
    radial-gradient(circle, #00f 0.4vmin, #0000 0) 20% 80%,
    radial-gradient(circle, #ff0 0.6vmin, #0000 0) 80% 20%,
    radial-gradient(circle, #00f 0.7vmin, #0000 0) 45% 55%,
    radial-gradient(circle, #00f 0.4vmin, #0000 0) 90% 10%,
    radial-gradient(circle, #ff0 0.3vmin, #0000 0) 35% 65%,
    radial-gradient(circle, #ff0 0.6vmin, #0000 0) 15% 85%,
    radial-gradient(circle, #0f0 0.5vmin, #f00 0) 30% 70%,
    radial-gradient(circle, #f00 0.7vmin, #00f 0) 75% 25%,
    radial-gradient(circle, #ff0 0.4vmin, #f0f 0) 55% 45%,
    radial-gradient(circle, #00f 0.3vmin, #0f0 0) 70% 30%,
    radial-gradient(circle, #f0f 0.6vmin, #ff0 0) 25% 75%,
    radial-gradient(circle, #ff0 0.5vmin, #f00 0) 40% 60%,
    radial-gradient(circle, #0f0 0.7vmin, #00f 0) 65% 35%,
    radial-gradient(circle, #00f 0.4vmin, #0f0 0) 15% 85%,
    radial-gradient(circle, #f00 0.2vmin, #f0f 0) 80% 20%,
    radial-gradient(circle, #f0f 0.5vmin, #ff0 0) 50% 50%,
    radial-gradient(circle, #0f0 0.6vmin, #00f 0) 90% 10%;
  background-size: 0.8vmin 0.8vmin;
  background-repeat: no-repeat;
  animation: firework 3s infinite;
}

.firework::before {
  transform: translate(-50%, -50%) rotate(25deg) !important;
}

.firework::after {
  transform: translate(-50%, -50%) rotate(-37deg) !important;
}

/* End firework animation */